import React, { useState } from "react";

const App = () => {
  const [step, setStep] = useState(1);
  const [zipCode, setZipCode] = useState("");
  const [electricBill, setElectricBill] = useState("");
  const [interest, setInterest] = useState("");
  const [recommendation, setRecommendation] = useState(null);

  const handleNext = () => {
    if (step === 3) {
      calculateRecommendation();
    } else {
      setStep(step + 1);
    }
  };

  const calculateRecommendation = () => {
    const bill = parseFloat(electricBill);
    const systemSize = Math.round((bill / 0.12) * 0.7); // Approximate system size in kW
    const savings = Math.round(bill * 12 * 0.7); // Approximate annual savings
    const backupPower = Math.round(systemSize * 1.5); // Approximate backup hours

    setRecommendation({
      systemSize: `${systemSize} kW`,
      annualSavings: `$${savings}`,
      backupPower: `${backupPower} hours`,
    });
  };

  const restartAgent = () => {
    setStep(1);
    setZipCode("");
    setElectricBill("");
    setInterest("");
    setRecommendation(null);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>GreenZap Solar Agent</h1>
      <div style={styles.agentBox}>
        {step === 1 && (
          <>
            <p>Welcome to GreenZap! What’s your zip code?</p>
            <input
              type="text"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              placeholder="Enter your zip code"
              style={styles.input}
            />
            <button
              onClick={handleNext}
              style={styles.button}
              disabled={!zipCode}
            >
              Next
            </button>
          </>
        )}
        {step === 2 && (
          <>
            <p>What’s your average monthly electricity bill?</p>
            <input
              type="number"
              value={electricBill}
              onChange={(e) => setElectricBill(e.target.value)}
              placeholder="Enter your bill in $"
              style={styles.input}
            />
            <button
              onClick={handleNext}
              style={styles.button}
              disabled={!electricBill}
            >
              Next
            </button>
          </>
        )}
        {step === 3 && (
          <>
            <p>Are you more interested in saving money or backup power?</p>
            <button
              onClick={() => {
                setInterest("saving money");
                handleNext();
              }}
              style={styles.button}
            >
              Saving Money
            </button>
            <button
              onClick={() => {
                setInterest("backup power");
                handleNext();
              }}
              style={styles.button}
            >
              Backup Power
            </button>
          </>
        )}
        {recommendation && (
          <>
            <p>Here’s what we recommend for your home in {zipCode}:</p>
            <ul>
              <li><strong>System Size:</strong> {recommendation.systemSize}</li>
              <li><strong>Annual Savings:</strong> {recommendation.annualSavings}</li>
              <li><strong>Backup Power:</strong> {recommendation.backupPower}</li>
            </ul>
            <p>
              Want to take the next step? Connect with a local installer!
              <br />
              <button onClick={restartAgent} style={styles.button}>
                Restart
              </button>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#f4f4f4",
    minHeight: "100vh",
  },
  title: {
    fontSize: "28px",
    marginBottom: "20px",
    color: "#2d572c",
  },
  agentBox: {
    maxWidth: "400px",
    margin: "0 auto",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#ffffff",
    cursor: "pointer",
    margin: "5px",
  },
};

export default App;
